
import { defineComponent, onMounted, PropType } from "vue";
import { Song } from "@/types/song";
import { useAppState } from "@/hooks/use-app-state";
import { useSongs } from "@/hooks/use-songs";
import { useUtils, ScrollOptions } from "@/hooks/use-utils";

export default defineComponent({
  props: {
    replaceRoute: {
      type: Boolean,
      default: false,
    },
    songs: {
      type: Object as PropType<Song[]>,
      required: true,
    },
    showLetter: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { settings } = useAppState();
    const { smoothScroll } = useUtils();
    const { getSongUrl } = useSongs();

    onMounted(() =>
      setTimeout(
        () =>
          smoothScroll(document.querySelector(".highlight") as HTMLElement, { offset: -100 } as ScrollOptions),
        1
      )
    );

    const renderLetter = (song: Song, index: number): string | null => {
      let letter = null;

      if (settings.value.songOverviewSortActive && props.showLetter) {
        const firstChar = song.title.charAt(0);
        const firstCharPrev =
          (props.songs[index - 1] && props.songs[index - 1].title.charAt(0)) ||
          "";
        const isLetter = /[a-zA-Z]/.test(song.title.charAt(0));

        if (isLetter) {
          letter =
            firstChar.toLowerCase() !== firstCharPrev.toLowerCase()
              ? firstChar.toUpperCase()
              : null;
        } else {
          letter = index == 0 ? "#" : null;
        }
      }

      return letter;
    };

    return { getSongUrl, renderLetter };
  },
});
