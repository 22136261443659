
import debounce from "lodash/debounce";
import { defineComponent, computed, ref } from "vue";
import { useAppState } from "@/hooks/use-app-state";
import { useSongs } from "@/hooks/use-songs";
import { useFilters } from "@/hooks/use-filters";
import { useAuth } from "@/hooks/use-auth";
import { useUser } from "@/hooks/use-user";
import SongList from "@/components/song/SongList.vue";
import mobile from "is-mobile";

export default defineComponent({
  setup() {
    const { loading, settings, saveSettings } = useAppState();
    const { songs } = useSongs();
    const { q, alphabeticSort, filteredSongs, sortedSongs } = useFilters();
    const { loggedIn } = useAuth();
    const { user } = useUser();

    const searchInput = ref(q);
    const searchInputRef = ref(null);
    const sortActive = ref(alphabeticSort);
    sortActive.value = settings.value.songOverviewSortActive || false;

    const formOpen = ref(searchInput.value ? true : false);
    const toggleFormOpen = () => (formOpen.value = !formOpen.value);
    const blurForm = () => {
      if (mobile() && searchInputRef.value) {
        // @ts-ignore: Object is possibly 'null'.
        searchInputRef.value.blur();
      }
    };
    const clearSearchInput = () => {
      searchInput.value = "";
    };

    const searchInputChanged = debounce((event: any) => {
      if (mobile()) {
        window.scroll(0, 0);
      }
      searchInput.value = event.target.value;
    }, 100);

    const toggleSortActive = () => {
      sortActive.value = !sortActive.value;
      settings.value.songOverviewSortActive = sortActive.value;
      saveSettings();
    };

    return {
      loading,
      songs: computed(() => sortedSongs(filteredSongs(songs.value))),
      loggedIn,
      isAdmin: computed(() => loggedIn.value && user && user.isAdmin),
      formOpen,
      toggleFormOpen,
      blurForm,
      clearSearchInput,
      searchInput,
      searchInputRef,
      searchInputChanged,
      sortActive,
      toggleSortActive,
    };
  },
  components: {
    SongList,
  },
});
