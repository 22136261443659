<template>
  <section class="song-overview">
    <div class="song-overview-top">
      <h1>Nummers</h1>
      <div class="song-overview-group" :class="{ 'offset-form': formOpen }">
        <button
          @click="toggleFormOpen"
          class="song-overview-search"
          :class="{ 'form-open': formOpen }"
          title="Zoeken"
        >
          <icon icon="search" />
        </button>
        <form
          @submit.prevent="blurForm"
          class="form"
          :class="{ 'is--open': formOpen }"
        >
          <div class="form-field">
            <input
              class="form-input"
              type="text"
              placeholder="Nummer/artiest zoeken"
              :value="searchInput"
              @input="searchInputChanged"
              @keyup.esc="clearSearchInput"
              ref="searchInputRef"
            />
            <icon v-show="!searchInput" icon="search" />

            <button
              v-show="searchInput"
              type="button"
              @click="clearSearchInput"
              class="form-input-clear"
              title="Verwijder input"
            >
              <icon icon="times" />
            </button>
          </div>
        </form>

        <button
          v-if="loggedIn"
          @click="toggleSortActive"
          class="song-overview-sort"
          :class="{ 'sort-active': sortActive }"
          title="Sorteer alfabetisch"
        >
          <icon icon="sort-alpha-down" />
        </button>
        <router-link
          v-if="isAdmin"
          :to="{ name: 'SongCreate' }"
          class="song-overview-add"
          title="Nummer toevoegen"
        >
          <icon icon="plus" />
        </router-link>
      </div>
    </div>
    <div class="song-overview-bottom">
      <SongList :songs="songs" />
      <p v-if="!songs.length && searchInput && !loading">
        Geen resultaten gevonden.
      </p>
      <p v-if="!songs.length && !searchInput && !loading">
        Je hebt nog geen nummers toegevoegd.
      </p>
      <p v-if="!songs.length && loading">Bezig met laden...</p>
    </div>
  </section>
</template>

<script lang="ts">
import debounce from "lodash/debounce";
import { defineComponent, computed, ref } from "vue";
import { useAppState } from "@/hooks/use-app-state";
import { useSongs } from "@/hooks/use-songs";
import { useFilters } from "@/hooks/use-filters";
import { useAuth } from "@/hooks/use-auth";
import { useUser } from "@/hooks/use-user";
import SongList from "@/components/song/SongList.vue";
import mobile from "is-mobile";

export default defineComponent({
  setup() {
    const { loading, settings, saveSettings } = useAppState();
    const { songs } = useSongs();
    const { q, alphabeticSort, filteredSongs, sortedSongs } = useFilters();
    const { loggedIn } = useAuth();
    const { user } = useUser();

    const searchInput = ref(q);
    const searchInputRef = ref(null);
    const sortActive = ref(alphabeticSort);
    sortActive.value = settings.value.songOverviewSortActive || false;

    const formOpen = ref(searchInput.value ? true : false);
    const toggleFormOpen = () => (formOpen.value = !formOpen.value);
    const blurForm = () => {
      if (mobile() && searchInputRef.value) {
        // @ts-ignore: Object is possibly 'null'.
        searchInputRef.value.blur();
      }
    };
    const clearSearchInput = () => {
      searchInput.value = "";
    };

    const searchInputChanged = debounce((event: any) => {
      if (mobile()) {
        window.scroll(0, 0);
      }
      searchInput.value = event.target.value;
    }, 100);

    const toggleSortActive = () => {
      sortActive.value = !sortActive.value;
      settings.value.songOverviewSortActive = sortActive.value;
      saveSettings();
    };

    return {
      loading,
      songs: computed(() => sortedSongs(filteredSongs(songs.value))),
      loggedIn,
      isAdmin: computed(() => loggedIn.value && user && user.isAdmin),
      formOpen,
      toggleFormOpen,
      blurForm,
      clearSearchInput,
      searchInput,
      searchInputRef,
      searchInputChanged,
      sortActive,
      toggleSortActive,
    };
  },
  components: {
    SongList,
  },
});
</script>

<style lang="scss">
$overview-icon-height-md: 50px;
$search-input-height-md: $nav-height-md;

.song-overview {
  display: flex;
  flex-direction: column;
  margin-bottom: #{$search-input-height-md + 10};

  &-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    @include max-md() {
      margin-bottom: 1rem;
    }

    h1 {
      margin: 0;
    }
  }

  &-group {
    display: flex;
    align-items: center;

    @include max-md() {
      flex-direction: column-reverse;
      position: fixed;
      bottom: #{$nav-height-md + $grid-padding};
      right: $grid-padding;
      z-index: 1000;

      &.offset-form {
        bottom: #{$nav-height-md + $grid-padding + $search-input-height-md};
      }

      @include max-sm() {
        @media (orientation: landscape) {
          bottom: #{$nav-height-md + $grid-padding};

          &.offset-form {
            bottom: #{$nav-height-md + $grid-padding};
          }
        }
      }
    }
  }

  &-add,
  &-sort,
  &-search {
    @include max-md() {
      height: $overview-icon-height-md;
      width: $overview-icon-height-md;
      margin-top: 0.6rem;
    }
  }

  &-add {
    @include button-icon-only(secondary);
  }

  &-sort {
    @include button-icon-only(secondary);
    @include min-md() {
      margin-right: 1rem;
    }

    &.sort-active {
      border-width: 3px;
    }
  }

  &-search {
    @include button-icon-only(secondary);

    @include min-md() {
      display: none !important;
    }

    @include max-md() {
      &.form-open {
        border-width: 3px;
      }
    }
  }

  .form {
    &:not(:last-child) {
      margin-right: 2rem;
    }

    @include max-md() {
      position: fixed;
      bottom: $nav-height-md;
      left: 0;
      right: 0;
      margin-right: 0 !important;
      backdrop-filter: blur(10px);

      &-input {
        padding: 14px 0 14px $grid-padding;
      }

      &:not(.is--open) {
        display: none;
      }

      @include max-sm() {
        @media (orientation: landscape) {
          bottom: 0;
        }
      }
    }

    &-field {
      margin-bottom: 0;

      @include max-md() {
        border-bottom: none;
        border-top: 1px solid var(--white-500);
      }
    }

    &-input-clear {
      @extend %button-reset;
    }

    &-input-clear,
    .fa-search {
      margin-left: 10px;
      width: 16px;

      @include max-md() {
        margin-right: $grid-padding;
      }
    }
  }
}
</style>