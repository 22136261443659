<template>
  <ol class="song-overview-list">
    <li
      class="song-overview-list-item"
      :class="{ highlight: song.recentlyAdded }"
      :data-letter="renderLetter(song, index)"
      v-for="(song, index) in songs"
      :key="`song-${index}`"
    >
      <router-link :to="getSongUrl(song, replaceRoute)">
        {{ song.title }}
        <small>{{ song.artist }}</small>
      </router-link>
    </li>
  </ol>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType } from "vue";
import { Song } from "@/types/song";
import { useAppState } from "@/hooks/use-app-state";
import { useSongs } from "@/hooks/use-songs";
import { useUtils, ScrollOptions } from "@/hooks/use-utils";

export default defineComponent({
  props: {
    replaceRoute: {
      type: Boolean,
      default: false,
    },
    songs: {
      type: Object as PropType<Song[]>,
      required: true,
    },
    showLetter: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { settings } = useAppState();
    const { smoothScroll } = useUtils();
    const { getSongUrl } = useSongs();

    onMounted(() =>
      setTimeout(
        () =>
          smoothScroll(document.querySelector(".highlight") as HTMLElement, { offset: -100 } as ScrollOptions),
        1
      )
    );

    const renderLetter = (song: Song, index: number): string | null => {
      let letter = null;

      if (settings.value.songOverviewSortActive && props.showLetter) {
        const firstChar = song.title.charAt(0);
        const firstCharPrev =
          (props.songs[index - 1] && props.songs[index - 1].title.charAt(0)) ||
          "";
        const isLetter = /[a-zA-Z]/.test(song.title.charAt(0));

        if (isLetter) {
          letter =
            firstChar.toLowerCase() !== firstCharPrev.toLowerCase()
              ? firstChar.toUpperCase()
              : null;
        } else {
          letter = index == 0 ? "#" : null;
        }
      }

      return letter;
    };

    return { getSongUrl, renderLetter };
  },
});
</script>

<style lang="scss">
@keyframes fadeOutHighlightColor {
  from {
    color: var(--white-500);
  }

  to {
    color: var(--black-500);
  }
}

@keyframes fadeOutHighlightBg {
  from {
    background-color: var(--black-500);
  }

  to {
    background-color: transparent;
  }
}

.song-overview-list {
  @extend %reset-list;
  counter-reset: song-counter;

  @include min-sm() {
    column-count: 2;
    column-gap: 100px;
  }

  @include min-md() {
    column-count: 3;
    column-gap: 100px;
  }

  &-item {
    position: relative;
    display: flex;
    padding: 5px 0;
    counter-increment: song-counter;
    break-inside: avoid-column;

    &:hover {
      @include font-weight-bold();
    }

    &.highlight {
      animation: fadeOutHighlightColor 6s ease-in-out;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -#{$grid-padding};
        right: -#{$grid-padding};
        z-index: -1;
        animation: fadeOutHighlightBg 6s ease-in-out;
      }
    }

    &::before {
      content: counter(song-counter);
      display: block;
      margin-right: 15px;
    }

    &[data-letter] {
      margin-top: 5px;
      padding-top: 10px;

      &:first-child {
        margin-top: 0;
      }

      &::after {
        content: attr(data-letter);
        @include font-weight-bold;
        text-align: right;
        border-top: 1px solid rgba(var(--white-500-rgb), 0.15);
        position: absolute;
        top: 0;
        left: 0;
        right: -12px;
        pointer-events: none;
      }
    }

    a,
    small {
      display: block;
      width: 100%;
    }

    a {
      @extend %link-reset;
    }
  }
}
</style>