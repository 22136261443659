import { reactive, toRefs } from "vue";
import { SongsState } from "./use-songs";
import { SongPartLineTypes } from "../types/song";

interface FiltersState {
    q: string,
    alphabeticSort: boolean
}

const defaultState: FiltersState = {
    q: "",
    alphabeticSort: false
}

export const useFilters = () => {
    const state = reactive<FiltersState>(defaultState);

    const filteredSongs = (songs: SongsState["data"]) => {

        if (state.q) {
            songs = songs.filter(x =>
                x.title.toLowerCase().includes(state.q.trim()) ||
                x.artist.toLowerCase().includes(state.q.trim()) ||
                (x.parts && x.parts.filter(part => part.lines.filter(line => line.type == SongPartLineTypes.TEXT && line.content.toLowerCase().includes(state.q.trim())).length > 0).length > 0));
        }

        return songs;
    }

    const sortedSongs = (songs: SongsState["data"]) => {

        if (state.alphabeticSort) {
            songs = [...songs].sort(function (a, b) {
                var textA = a.title.toLowerCase();
                var textB = b.title.toLowerCase();
                return textA.localeCompare(textB);
            });
        }

        return songs;
    }

    const reset = () => {
        Object.assign(state, defaultState);
    };

    return { ...toRefs(state), filteredSongs, sortedSongs, reset };
}